import * as actionTypes from './actionTypes';
import axios from "../../api/axios-peer";
import {AxiosInstance, AxiosRequestConfig} from "axios";
import {DownloadInfo, GetDownloadUrlParams, StateDatatable} from "../interfaces/datatableInterface";
import {State} from "../interfaces/stateInterface";
import axiosPeer from "../../api/axios-peer";
import axiosFormklub from "../../api/axios-formklub";

type Dispatch = (action: any) => void;
type Callback = ((data: any) => void) | null;
type CatchError = ((error: any) => void) | null;


/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const fetchDataStart = (index: string) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_START,
        index: index
    }
}

/**
 *
 * @param error
 * @param index
 * @returns {{index, type: string, error}}
 */
export const fetchDataFail = (error: string, index: string) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_FAIL,
        error: error,
        index: index
    }
}

/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const setTableInitialized = (index: string) => {
    return {
        type: actionTypes.SET_TABLE_INITIALIZED,
        index: index
    }
}

/**
 *
 * @param url
 * @param index
 * @param numberOfRow
 * @param page
 * @param sortOrder
 * @param urlAddition
 * @param searchParams
 * @param activeTabFilter
 * @param resource
 * @param callback
 * @param routeFilter
 * @returns {{routeFilter: null, resource: null, sortOrder: null, numberOfRow, index, activeTabFilter: null, callback: null, page, type: string, urlAddition: null, url, searchParams: null}}
 */
export const fetchDataRequest = (url: string, index: string, numberOfRow: number, page: number, sortOrder: string | null = null, urlAddition: string | null = null, searchParams: string | null = null, activeTabFilter: string | null = null, resource: AxiosInstance | null = null, callback: Callback = null, routeFilter: string | null = null) => ({
    type: actionTypes.FETCH_DATA_REQUEST_SAGA,
    url,
    index,
    numberOfRow,
    page,
    sortOrder,
    urlAddition,
    searchParams,
    activeTabFilter,
    resource,
    callback,
    routeFilter
});

/**
 * @deprecated use fetchTabsRequest
 * @param url
 * @param index
 * @param resource
 * @param callback
 * @returns {(function(*): void)|*}
 */
export const fetchTabs = (
    url: string,
    index: string,
    resource: AxiosInstance | null = null,
    callback: Callback = null
) => {
    return (dispatch: Dispatch) => {
        dispatch(fetchDataStart(index));

        // Append "tabs" to the URL
        url += url.endsWith('/') ? "tabs" : "/tabs";

        const rest = resource ?? axios;
        rest.get(url, {
            responseType: 'json',
        }).then(response => {
            if (callback) {
                callback(response.data);
            }
            dispatch(fetchDataSuccess(index, response.data));
        }).catch(error => {
            dispatch(fetchDataFail(error.message, index));
        });
    };
};

/**
 *
 * @param url
 * @param index
 * @param numberOfRow
 * @param page
 * @param sortOrder
 * @param urlAddition
 * @param searchParams
 * @param activeTabFilter
 * @param resource
 * @param callback
 * @param routeFilter
 * @returns {(function(*): void)|*}
 */
export const fetchData = (
    url: string,
    index: string,
    numberOfRow: number,
    page: number,
    sortOrder: string | null = null,
    urlAddition: string | null = null,
    searchParams: string | null = null,
    activeTabFilter: string | null = null,
    resource: AxiosInstance | null = null,
    callback: Callback = null,
    routeFilter?: string | null
) => {
    return (dispatch: Dispatch) => {
        dispatch(fetchDataStart(index));

        const rest = resource ?? axios;

        let questionmarked = url.includes("?");

        if (numberOfRow !== null) {
            url += (questionmarked ? "&" : "?") + "per-page=" + numberOfRow;
            questionmarked = true;
        }

        if (page !== null) {
            url += (questionmarked ? "&" : "?") + "page=" + (page + 1);
            questionmarked = true;
        }

        if (urlAddition != null) {
            url += (questionmarked ? "&" : "?") + urlAddition;
            questionmarked = true;
        }

        if (searchParams != null) {
            url += (questionmarked ? "&" : "?") + searchParams;
            questionmarked = true;
        }

        if (routeFilter != null) {
            url += (questionmarked ? "&" : "?") + routeFilter;
            questionmarked = true;
        }

        if (activeTabFilter) {
            url += (questionmarked ? "&" : "?") + activeTabFilter;
            questionmarked = true;
        }

        if (sortOrder != null) {
            url += (questionmarked ? "&" : "?") + "sort=" + sortOrder;
        }

        rest.get(url, {responseType: 'json'})
            .then(response => {
                if (callback) {
                    callback(response.data);
                }
                dispatch(fetchDataSuccess(index, response.data));
            })
            .catch(error => {
                dispatch(fetchDataFail(error.message, index));
                dispatch(setFormAlert(true, 'error', error.message));
            });
    };
};

/**
 *
 * @param url
 * @param index
 * @param resource
 * @param callback
 * @returns {{resource: null, index, callback: null, type: string, url}}
 */
export const fetchTabsRequest = (url: string, index: string, resource: AxiosInstance | null = null, callback: Callback = null) => {
    return ({
        type: actionTypes.FETCH_TABS_REQUEST_SAGA,
        url,
        index,
        resource,
        callback
    });
};

/**
 *
 * @returns {{type: string}}
 */
export const saveDataStart = () => {
    return {
        type: actionTypes.SAVE_DATA_START
    }
}

/**
 *
 * @param data
 * @param index
 * @param dataId
 * @returns {{data, dataId, index, filters, type: string}}
 */
export const saveDataSuccess = (data: StateDatatable, index: string, dataId: [] | number) => {
    return {
        type: actionTypes.SAVE_DATA_SUCCESS,
        data: data,
        index: index,
        dataId: dataId,
        filters: data?.filters
    }
}

/**
 *
 * @param state
 * @returns {{state, type: string}}
 */
export const toggleLoading = (state: boolean) => {
    return {
        type: actionTypes.TOGGLE_LOADING,
        state: state,
    }
}

/**
 *
 * @param data
 * @param index
 * @returns {{data, index, filters, type: string}}
 */
export const addDataSucces = (data: StateDatatable, index: string) => {
    return {
        type: actionTypes.ADD_DATA_SUCCESS,
        data: data,
        index: index,
        filters: data.filters,
    }
}

/**
 *
 * @param dataId
 * @param index
 * @returns {{dataId, index, type: string}}
 */
export const deleteDataSuccess = (dataId: number, index: string) => {
    return {
        type: actionTypes.DELETE_DATA_SUCCESS,
        dataId: dataId,
        index: index
    }
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
export const saveDataFail = (error: string) => {
    return {
        type: actionTypes.SAVE_DATA_FAIL,
        error: error
    }
}

/**
 *
 * @param url
 * @param data
 * @param index
 * @param dataId
 * @param callback
 * @param catchError
 * @param baseUrl
 * @returns {(function(*): void)|*}
 */
export const updateData = (
    url: string,
    data: StateDatatable,
    index: string,
    dataId: number,
    callback: Callback | null = null,
    catchError: CatchError | null = null,
    baseUrl: string
) => {
    return async (dispatch: Dispatch) => {
        dispatch(saveDataStart());

        const config: AxiosRequestConfig = {
            baseURL: baseUrl,  // Set the base URL here
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json', // Optional: Customize headers if needed
            },
        };

        try {
            const response = await axios.put(url, data, config);

            const responseData = response.data;

            dispatch(saveDataSuccess(responseData, index, dataId)); // Dispatch success action

            if (callback) {
                callback(responseData); // Call the callback with the response data if provided
            }

        } catch (error: any) {
            console.error('Error during PUT request:', error);

            // Check if `catchError` is provided and call it with appropriate error details
            if (catchError && error.response) {
                if (error.response.data.validationErrors) {
                    catchError(error.response.data.validationErrors);
                } else {
                    catchError(error.response.data);
                }
            }

            dispatch(setFormAlert(true, 'error', error.response?.statusText || error.message));

            throw error;  // Re-throw error for additional handling if necessary
        }
    };
};

/**
 *
 * @param url
 * @param data
 * @param index
 * @param dataId
 * @param callback
 * @param catchError
 * @param baseUrl
 * @returns {(function(*): void)|*}
 */
export const patchData = (
    url: string,
    data: any,              // Adjust this type based on the structure of your data
    index: string,
    dataId: [],
    callback: Callback | null = null,
    catchError: CatchError | null = null,
    baseUrl: string
) => {
    return (dispatch: Dispatch) => {
        dispatch(saveDataStart());

        const config: AxiosRequestConfig = {
            baseURL: baseUrl,
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios.patch(url, data, config)
            .then(response => {
                const responseData = response.data;
                dispatch(saveDataSuccess(responseData, index, dataId));

                if (callback) {
                    callback(responseData);
                }
                // Uncomment the next line if you need to close the data record after successful patch
                // dispatch(closeDataRecord());
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors);
                    } else {
                        catchError(error.response.data);
                    }
                }

                dispatch(setFormAlert(true, 'error', error.response?.statusText || error.message));
            });
    };
};

/**
 *
 * @param url
 * @param data
 * @param index
 * @param keepOpen
 * @param callback
 * @param catchError
 * @param skipSaveData
 * @returns {(function(*): void)|*}
 */
export const postData = (
    url: string,
    data: any,
    index: string,
    keepOpen: boolean = false,
    callback: Callback | null = null,
    catchError: CatchError | null = null,
    skipSaveData: boolean = false
) => {
    return (dispatch: Dispatch) => {

        if (!skipSaveData) {
            dispatch(saveDataStart());
        }

        axios.post(url, data)
            .then(response => {
                let responseData = response.data;

                // Ensure responseData is an array
                if (!Array.isArray(responseData)) {
                    responseData = [response.data];
                }

                if (!skipSaveData) {
                    dispatch(addDataSucces(responseData, index));
                }

                if (callback) {
                    callback(responseData[0]);
                    if (catchError) {
                        catchError(null);
                    }
                }

                if (!keepOpen) {
                    dispatch(setAddFormOpen(false));
                }
            })
            .catch((error) => {
                if (catchError && error.response) {
                    if (error.response.data.validationErrors !== undefined) {
                        catchError(error.response.data.validationErrors);
                        dispatch(setFormAlert(true, 'error', catchError.toString()));
                    } else {
                        catchError(error.response.data);
                        if (error.response.data.length > 0) {
                            error.response.data.forEach((errorItem: { field?: string; message: string }) => {
                                if (errorItem.field !== undefined) {
                                    dispatch(setFormAlert(true, 'error', errorItem.message));
                                }
                            });
                        } else {
                            const errorMessage = error.response.data.message;
                            dispatch(setFormAlert(true, 'error', errorMessage));
                        }
                    }
                }
            });
    };
};

/**
 *
 * @param url
 * @param index
 * @param dataId
 * @returns {(function(*): void)|*}
 */
export const deleteData = (url: string, index: string, dataId: number) => {
    return (dispatch: Dispatch) => {
        axios.delete(url)
            .then(() => {
                dispatch(deleteDataSuccess(dataId, index))
            })
            .catch((error) => {
                dispatch(fetchDataFail(error.message, index))
            })
    };
}

/**
 *
 * @param index
 * @param data
 * @returns {{index, type: string, object: {tabsInitialized: boolean, meta: NetworkMeta, columns, tabs: *, links: *, filters, items, open: boolean, defaultTabIndex: *}}}
 */
export const fetchDataSuccess = (index: string, data: StateDatatable) => {
    return {
        type: actionTypes.FETCH_DATA_TABLE_SUCCESS,
        index: index,
        object: {
            items: data.items,
            meta: data._meta,
            links: data._links,
            columns: data.columns,
            filters: data.filters,
            tabs: data.tabs,
            open: false,
            tabsInitialized: true,
            defaultTabIndex: data.defaultTabIndex,
        },
    }
}

/**
 *
 * @param state
 * @param index
 * @param disabled
 * @param openRecord
 * @param action
 * @returns {{openRecord, index, action, disabled: boolean, type: string, open}}
 */
export const openDialogData = (state: State, index: string, disabled: boolean = true, openRecord: number, action: string | null) => {
    return {
        type: actionTypes.OPEN_DIALOG_TABLE,
        open: state,
        index: index,
        disabled: disabled,
        openRecord: openRecord,
        action: action,
    };
}

/**
 *
 * @param state
 * @param index
 * @param disabled
 * @returns {{openRecord: null, index, disabled: boolean, type: string, open: boolean}}
 */
export const closeDialogData = (state: boolean, index: string, disabled: boolean = true) => {
    return {
        type: actionTypes.CLOSE_DIALOG_TABLE,
        open: false,
        index: index,
        disabled: disabled,
        openRecord: null
    };
}

/**
 *
 * @param dataIndex
 * @param index
 * @param action
 * @returns {{openRecord, index, action: null, type: string}}
 */
export const openDataRecord = (dataIndex: number, index: string, action: string | null = null) => {
    return {
        type: actionTypes.SELECT_DATA_TABLE_ROW,
        openRecord: dataIndex,
        index: index,
        action: action
    };
}

/**
 *
 * @param index
 * @returns {{openRecord: null, index, action: null, type: string}}
 */
export const closeDataRecord = (index: string) => {
    return {
        type: actionTypes.CLOSE_DATA_TABLE_ROW,
        index: index,
        openRecord: null,
        action: null
    };
}

/**
 *
 * @param dataIndex
 * @param index
 * @returns {{selectedRows, index, type: string}}
 */
export const setSelectedTableRows = (dataIndex: string, index: string) => {
    return {
        type: actionTypes.SELECT_TABLE_ROWS,
        selectedRows: dataIndex,
        index: index,
    };
}

/**
 *
 * @param handle
 * @returns {{handle, type: string}}
 */
export const setActionHandle = (handle: string) => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: handle,
    };
}

/**
 *
 * @param state
 * @returns {{isDisabled, type: string}}
 */
export const setDisabled = (state: State) => {
    return {
        type: actionTypes.SET_DISABLED,
        isDisabled: state,
    };
}

/**
 *
 * @returns {{handle: null, type: string}}
 */
export const clearActionHandle = () => {
    return {
        type: actionTypes.SET_ACTION_HANDLE,
        handle: null,
    };
}

/**
 *
 * @returns {{isEdited: boolean, type: string}}
 */
export const setFormNotEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: false,
    };
}

/**
 *
 * @returns {{isEdited: boolean, type: string}}
 */
export const setFormEdited = () => {
    return {
        type: actionTypes.FORM_EDITED,
        isEdited: true,
    };
}

/**
 *
 * @param error
 * @returns {{type: string, error}}
 */
export const setFormError = (error: string) => {
    return {
        type: actionTypes.SET_ERROR,
        error: error,
    };
}

/**
 *
 * @param state
 * @param variant
 * @param message
 * @param persist
 * @param duration
 * @returns {{duration: null, variant, state, persist: null, type: string, message}}
 */
export const setFormAlert = (state: boolean, variant: string, message: string, persist = null, duration = null) => {
    return {
        type: actionTypes.SET_ALERT,
        state: state,
        variant: variant,
        message: message,
        persist: persist,
        duration: duration,
    };
}

/**
 *
 * @param isOpen
 * @returns {{isOpen, type: string}}
 */
export const setAddFormOpen = (isOpen: boolean) => {
    return {
        type: actionTypes.SET_ADD_FORM_OPEN,
        isOpen: isOpen
    };
}

/**
 *
 * @param index
 * @param newTab
 * @param currentTab
 * @returns {{tab, index, currentTab: null, type: string}}
 */
export const setCurrentTab = (index: string, newTab: number, currentTab: number | null = null) => {

    if (newTab === currentTab) {
        return;
    }
    return {
        type: actionTypes.SET_TAB_REQUEST_SAGA,
        index: index,
        tab: newTab,
        currentTab: currentTab
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const resetDataTable = () => {
    return {
        type: actionTypes.RESET_DATA_TABLE
    }
}

/**
 *
 * @param index
 * @param dataId
 * @param data
 * @returns {{dataId, data, index, type: string}}
 */
export const updateDataTableRecord = (index: string, dataId: number, data: object) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}

/**
 *
 * @param index
 * @param dataId
 * @param data
 * @returns {{dataId, data, index, type: string}}
 */
export const insertDataTableRecord = (index: string, dataId: number, data: object) => {
    return {
        type: actionTypes.INSERT_DATA_TABLE_RECORD,
        index: index,
        dataId: dataId,
        data: data,
    }
}

/**
 *
 * @param index
 * @param rowIndex
 * @returns {{index, rowIndex, type: string}}
 */
export const removeDataTableRecord = (index: string, rowIndex: number) => {
    return {
        type: actionTypes.REMOVE_DATA_TABLE_RECORD,
        index: index,
        rowIndex: rowIndex,
    }
}

/**
 *
 * @param index
 * @returns {{index, type: string}}
 */
export const clearDataTableIndex = (index: string) => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_INDEX,
        index: index,
    }
}

/**
 *
 * @param index
 * @param action
 * @returns {{index, action, type: string}}
 */
export const setDataTableAction = (index: string, action: string) => {
    return {
        type: actionTypes.SET_DATA_TABLE_ACTION,
        index: index,
        action: action
    }
}

/**
 *
 * @param index
 * @param toggled
 * @returns {{toggled, index, type: string}}
 */
export const setExpandedTableRows = (index: string, toggled: boolean) => {
    return {
        type: actionTypes.EXPAND_TABLE_ROWS,
        index: index,
        toggled: toggled
    }
}

/**
 *
 * @param index
 * @param rowIndex
 * @param columnName
 * @param newValue
 * @returns {{newValue, index, rowIndex, type: string, columnName}}
 */
export const updateDataTableSingleValue = (index: string, rowIndex: number, columnName: string, newValue: string | number | null) => {
    return {
        type: actionTypes.UPDATE_DATA_TABLE_VALUE,
        index: index,
        rowIndex: rowIndex,
        columnName: columnName,
        newValue: newValue,
    }
}

/**
 *
 * @param index
 * @param state
 * @param rowIndex
 * @returns {{index, rowIndex, state, type: string}}
 */
export const setDataTableMode = (index: string, state: string, rowIndex: number) => {
    return {
        type: actionTypes.SET_DATA_TABLE_MODE,
        index: index,
        state: state,
        rowIndex: rowIndex,
    }
}

/**
 *
 * @param index
 * @param exportTable
 * @param companyUUID
 * @returns {{index, exportTable, company, type: string}}
 */
export const exportDataTable = (index: string, exportTable: object | null, companyUUID: string) => {
    return {
        type: actionTypes.EXPORT_DATA_TABLE,
        index: index,
        exportTable: exportTable,
        company: companyUUID
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const clearTableSettings = () => {
    return {
        type: actionTypes.CLEAR_DATA_TABLE_SETTINGS,
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const clearAllTableSettings = () => {
    return {
        type: actionTypes.CLEAR_ALL_DATA_TABLE_SETTINGS,
    };
}

/**
 *
 * @param index
 * @param field
 * @param value
 * @returns {{field, index, type: string, value}}
 */
export const updateReduxFilters = (index: string, field: string, value: string) => {
    return {
        type: actionTypes.UPDATE_REDUX_FILTERS,
        index: index,
        field: field,
        value: value
    };
}

/**
 * Get Export data for excel or pdf
 * @param params
 */
export const getDownloadUrlExport = async (
    {
        index,
        searchParams,
        urlAddition,
        scope,
        tableTabs,
        tableCurrentTab,
        tableData,
        tableSelectedRows,
    }: GetDownloadUrlParams): Promise<{ downloadInfo: DownloadInfo; data: any } | undefined> => {

    const indexDownload = index.replace("_", "-");
    const filters = searchParams ? `&${searchParams}` : "";
    const urlExtra = urlAddition ? `&${urlAddition}` : "";
    let tabState = "";

    // Check for tabs and current state
    if (tableTabs && typeof tableCurrentTab === "number") {
        const tabKey = Object.keys(tableTabs)[tableCurrentTab];
        if (tabKey) {
            tabState = `&${tableTabs[tabKey]}`;
        }
    }

    // Handle selected rows
    const selectedRowId: string[] = [];
    if (tableData && tableSelectedRows?.length) {
        tableSelectedRows.forEach((rowIndex) => {
            const row = tableData[rowIndex];
            if (row) {
                const filterBy = row.uuid ? "uuid" : "id";
                selectedRowId.push(`&filter[${filterBy}][]=${row[filterBy]}`);
            }
        });
    }

    // Get Scope
    let scopeInstance: any; // Replace `any` with actual type
    switch (scope) {
        case "PEER":
            scopeInstance = axiosPeer;
            break;
        case "FORMKLUB":
            scopeInstance = axiosFormklub;
            break;
        default:
            throw new Error("Invalid scope provided.");
    }

    // Set download URL
    const baseUrl = `/${indexDownload}?per-page=0&download=1`;
    const url = selectedRowId.length
        ? `${baseUrl}${selectedRowId.join("")}${tabState}`
        : `${baseUrl}${urlExtra}${filters}${tabState}`;

    try {
        // Fetch data
        const response = await scopeInstance.get(url, {
            responseType: "json",
        });

        return response;
    } catch (error) {
        console.error("Error fetching download data:", error);
        return undefined;
    }
};